import React from "react";
import Signupform from "@/components/Signupform";

export default function Signup() {
  return (
    <div>
      <Signupform />
    </div>
  );
}
