import React from "react";
import Loginform from "../components/Loginform";

export default function Login() {
  return (
    <div>
      <Loginform />
    </div>
  );
}
