import React from 'react'
import './Loader.css'; // Import your CSS file for styling

const Loader = () => {
  return (
  // You can set loading to false when your data is ready to be displayed
    // <div className={`loader-container ${loading ? 'visible' : 'hidden'}`}>
    //   <div className="loader"></div>
    // </div>
    
<div className="loader"></div>
  )
}

export default Loader